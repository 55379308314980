import { BroadcastChannel } from 'broadcast-channel';
import i18next from 'i18next';
import { Action } from 'redux-actions';
import { EventChannel, eventChannel } from 'redux-saga';
import {
  call,
  fork,
  put,
  race,
  select,
  take,
  takeLatest,
} from 'redux-saga/effects';

import {
  convertImageTo,
  ImageMimeTypes,
  PDFCreator,
} from '@ac/kiosk-components';
import { isApiError, Unsubscribe } from '@ac/library-api';
import { ApiError } from '@ac/library-api/dist/types/entities/shared/errors/apiError';
import { getSafeExternalContent } from '@ac/library-utils/dist/utils';

import {
  ElectronicRegistrationApi,
  ElectronicRegistrationProcessApi,
} from 'api/KioskApi';
import {
  KioskBusinessDate,
  KioskCommunicationType,
  KioskConfigurationConsent,
  KioskConfigurationProperty,
  KioskDictionaryEntryDetails,
  KioskLibraryApiResponse,
  KioskRegCardDetails,
  KioskReservationHeaderDefinition,
} from 'api/KioskApi/entries';
import {
  BackendNotificationTypes,
  ERegistrationCardChangedNotification,
} from 'api/KioskApi/notifications';
import {
  CROSSTAB_COMMUNICATION_CHANNEL_NAME,
  KIOSK_SESSION_ID_HEADER,
  knownErrorCodes,
} from 'configs';
import { BackendNotificationsClient, Storage } from 'services';
import {
  getCurrentDeviceId,
  getIsSameDeviceScenario,
} from 'store/app/selectors';
import {
  changeAppLanguage,
  refetchConfiguration,
  updateBusinessDate,
} from 'store/settings/actions';
import {
  CustomMessagesSettingsStorage,
  DateTimeFormats,
  GeneralSettingsStorage,
  ImagesSettingsStorage,
} from 'store/settings/interfaces';
import {
  getAddressTypeEntities,
  getCommunicationModeOrder,
  getCommunicationTypeEntities,
  getConsentsEntities,
  getCustomMessages,
  getDateTimeFormats,
  getGeneralSettings,
  getImages,
  getPropertyConfiguration,
  getRefetchingConfigurationFetchState,
  getReservationHeaderDefinition,
} from 'store/settings/selectors';
import { blobToBase64, DateManager, objectUrlToBlob } from 'utils';
import {
  mapAddressData,
  mapComplementaryData,
  mapConsentsData,
  mapContactsData,
  mapDocumentsData,
  mapPersonalData,
  mapPurposeOfStay,
  mapReservationData,
} from 'utils/regCardPresentationDataMappers';

import { SagasGenerator } from 'types/shared';

import {
  createRegistrationCardPdfContent,
  RegistrationCardPdfConfig,
  RegistrationCardPdfData,
} from './utils/createRegistrationCardPdfContent';
import * as actions from './actions';
import { UpdateConsentsAndStayPayload } from './interfaces';
import {
  getElectronicRegistrationProcessId,
  getIsRegistrationCardCancelled as getRegistrationCardCancelled,
  getIsRegistrationCardCompleted as getRegistrationCardCompleted,
  getRegistrationCardDetails,
  getRegistrationCardId,
} from './selectors';

function* startElectronicRegistrationProcess(): SagasGenerator {
  try {
    const isFetchingConfiguration: boolean = yield select(
      getRefetchingConfigurationFetchState
    );
    if (isFetchingConfiguration) {
      yield take(refetchConfiguration.done);
    }
    const cardId: string = yield select(getRegistrationCardId);
    const currentDeviceId: string = yield select(getCurrentDeviceId);
    const isSameDeviceScenario: string = yield select(getIsSameDeviceScenario);
    const propertyConfiguration: KioskConfigurationProperty = yield select(
      getPropertyConfiguration
    );

    const regCard: KioskLibraryApiResponse<undefined> = yield ElectronicRegistrationProcessApi.startElectronicRegistrationProcess(
      {
        data: {
          cardId,
          deviceId: currentDeviceId,
        },
        fullResponse: true,
      }
    );

    const {
      businessDate,
    }: KioskBusinessDate = yield ElectronicRegistrationApi.getBusinessDate();

    const currentBusinessDate = propertyConfiguration?.businessDate;

    if (currentBusinessDate !== businessDate) {
      yield put(updateBusinessDate(currentBusinessDate));
    }

    const kioskSessionId =
      regCard.headers[KIOSK_SESSION_ID_HEADER.toLowerCase()];

    yield put(actions.fetchRegistrationCardDetails.trigger(kioskSessionId));
    yield put(
      actions.startElectronicRegistrationProcess.success(kioskSessionId)
    );

    if (isSameDeviceScenario) {
      const cardStatusListener: EventChannel<boolean> = yield call(
        sameDeviceCardStatusChangeListener,
        cardId,
        kioskSessionId
      );
      const activeTabListener: EventChannel<boolean> = yield call(
        keepSameDeviceOneTabActiveOnlyListener,
        cardId
      );

      yield fork(
        sameDeviceSessionChangeListenerManager,
        cardStatusListener,
        activeTabListener
      );
    }
  } catch (e) {
    yield put(actions.startElectronicRegistrationProcess.failure(e));
  }
}

function* sameDeviceCardStatusChangeListener(
  cardId: string,
  kioskSessionId: string
): SagasGenerator<EventChannel<boolean>> {
  const channel: EventChannel<boolean> = yield eventChannel<boolean>(
    (emitter) => {
      let unsubscribeWebSocket: Unsubscribe;
      BackendNotificationsClient.user
        .subscribe<ERegistrationCardChangedNotification>(
          {
            notificationType: BackendNotificationTypes.ERegistrationCardChanged,
          },
          async (message) => {
            if (message?.notification?.eRegistrationCardId === cardId) {
              try {
                await ElectronicRegistrationProcessApi.getProcessRegCardDetails(
                  {
                    customConfig: {
                      headers: {
                        [KIOSK_SESSION_ID_HEADER]: kioskSessionId,
                      },
                    },
                  }
                );
              } catch (error) {
                const errorData = (error as { data: ApiError }).data;

                const isSessionClosed =
                  isApiError(errorData) &&
                  errorData.details.some(
                    ({ code }) =>
                      code === knownErrorCodes.INVALID_KIOSK_SESSION.code
                  );

                if (isSessionClosed) {
                  emitter(true);
                }
              }
            }
          }
        )
        .then(({ unsubscribe }) => {
          unsubscribeWebSocket = unsubscribe;
        });

      return (): void => {
        unsubscribeWebSocket?.();
      };
    }
  );

  return channel;
}

function* keepSameDeviceOneTabActiveOnlyListener(
  cardId: string
): SagasGenerator<EventChannel<boolean>> {
  const CrossTabCommunication = new BroadcastChannel<ERegistrationCardChangedNotification>(
    CROSSTAB_COMMUNICATION_CHANNEL_NAME,
    {
      webWorkerSupport: false,
    }
  );

  CrossTabCommunication.postMessage({
    eRegistrationCardId: cardId,
  });

  const channel: EventChannel<boolean> = yield eventChannel<boolean>(
    (emitter) => {
      CrossTabCommunication.onmessage = (message): void => {
        if (message?.eRegistrationCardId === cardId) {
          emitter(true);
        }
      };

      return (): void => {
        CrossTabCommunication.close();
      };
    }
  );

  return channel;
}

function* sameDeviceSessionChangeListenerManager(
  cardStatusListener: EventChannel<boolean>,
  activeTabListener: EventChannel<boolean>
): SagasGenerator {
  const [
    didNewSessionWasOpenedInNewCard,
    didCardStatusChange,
  ]: boolean[] = yield race([
    take(activeTabListener),
    take(cardStatusListener),
    take(actions.cancelElectronicRegistrationProcess.success),
    take(actions.completeRegistrationCard),
  ]);

  if (didNewSessionWasOpenedInNewCard) {
    yield put(actions.anotherSessionOpenedInNewTab());
  } else if (didCardStatusChange) {
    yield put(actions.cancelElectronicRegistrationProcess.success());
  }

  cardStatusListener.close();
  activeTabListener.close();
}

function* cancelElectronicRegistrationProcess(): SagasGenerator {
  try {
    const kioskSessionId: string = yield select(
      getElectronicRegistrationProcessId
    );
    const cardId: string = yield select(getRegistrationCardId);

    yield ElectronicRegistrationProcessApi.cancelElectronicRegistrationProcess({
      customConfig: {
        headers: {
          [KIOSK_SESSION_ID_HEADER]: kioskSessionId,
        },
      },
    });

    Storage.savePreviouslyClosedRegistrationCard(cardId);

    yield put(actions.cancelElectronicRegistrationProcess.success());
  } catch (e) {
    yield put(actions.cancelElectronicRegistrationProcess.failure(e));
  }
}

function* fetchRegistrationCardDetails(action: Action<string>): SagasGenerator {
  try {
    const regCard: KioskRegCardDetails = yield ElectronicRegistrationProcessApi.getProcessRegCardDetails(
      {
        customConfig: {
          headers: {
            [KIOSK_SESSION_ID_HEADER]: action.payload,
          },
        },
      }
    );

    yield put(actions.fetchRegistrationCardDetails.success(regCard));
  } catch (e) {
    yield put(actions.fetchRegistrationCardDetails.failure(e));
  }
}

function* updateConsentsAndPurposeOfStay(
  action: Action<UpdateConsentsAndStayPayload>
): SagasGenerator {
  try {
    const sessionId: string = yield select(getElectronicRegistrationProcessId);
    const { purposeOfStayId, etd, consents } = action.payload;

    if (purposeOfStayId || etd) {
      yield ElectronicRegistrationProcessApi.updateStay({
        data: {
          purposeOfStayId,
          etd,
        },
        customConfig: {
          headers: {
            [KIOSK_SESSION_ID_HEADER]: sessionId,
          },
        },
      });
    }

    if (consents) {
      yield ElectronicRegistrationProcessApi.updateConsents({
        data: consents,
        customConfig: {
          headers: {
            [KIOSK_SESSION_ID_HEADER]: sessionId,
          },
        },
      });
    }

    if (purposeOfStayId || etd || consents) {
      yield put(actions.fetchRegistrationCardDetails.trigger(sessionId));
    }

    if (action.payload.onSuccessfulUpdate) {
      yield call(action.payload.onSuccessfulUpdate);
    }

    yield put(actions.updateConsentsAndPurposeOfStay.success());
  } catch (e) {
    yield put(actions.updateConsentsAndPurposeOfStay.failure(e));
  }
}

function* getPDFPresentationData(
  signatureBlob: Blob
): SagasGenerator<RegistrationCardPdfData> {
  const consents: KioskConfigurationConsent[] | undefined = yield select(
    getConsentsEntities
  );
  const reservationHeaderDefinition:
    | KioskReservationHeaderDefinition[]
    | undefined = yield select(getReservationHeaderDefinition);

  const communicationTypes: KioskCommunicationType[] | undefined = yield select(
    getCommunicationTypeEntities
  );
  const addressTypes: KioskDictionaryEntryDetails[] | undefined = yield select(
    getAddressTypeEntities
  );
  const communicationModeOrder: string[] = yield select(
    getCommunicationModeOrder
  );
  const propertyConfiguration:
    | KioskConfigurationProperty
    | undefined = yield select(getPropertyConfiguration);
  const customMessages:
    | CustomMessagesSettingsStorage
    | undefined = yield select(getCustomMessages);
  const dateTimeFormats: DateTimeFormats = yield select(getDateTimeFormats);
  const generalSettings: GeneralSettingsStorage | undefined = yield select(
    getGeneralSettings
  );
  const images: ImagesSettingsStorage | undefined = yield select(getImages);
  const cardDetails: KioskRegCardDetails = yield select(
    getRegistrationCardDetails
  );

  const date = DateManager.getFormattedDate(
    propertyConfiguration?.businessDate,
    dateTimeFormats?.shortDateFormat
  );
  const time = DateManager.getFormattedTime(
    new Date(),
    dateTimeFormats?.timeFormat
  );

  const logoBlob: Blob | undefined = images?.LOGO
    ? yield objectUrlToBlob(images.LOGO)
    : undefined;

  const convertedLogo: Blob | undefined =
    logoBlob && logoBlob.type === ImageMimeTypes.PNG
      ? yield convertImageTo(logoBlob, ImageMimeTypes.JPEG)
      : logoBlob;

  const logoBase64: string | undefined = convertedLogo
    ? yield blobToBase64(convertedLogo)
    : undefined;

  const signatureBase64: string = yield blobToBase64(signatureBlob);

  const pdfPresentationData: RegistrationCardPdfData = {
    reservationSubsections: mapReservationData(cardDetails.reservation, {
      longDateFormat: dateTimeFormats?.longDateFormat,
      timeFormat: dateTimeFormats?.timeFormat,
      isMembershipEnabled: generalSettings?.DISPLAY_MEMBERSHIP,
    }),
    personalSubsection: mapPersonalData(cardDetails.profile.personalDetails, {
      shortDateFormat: dateTimeFormats?.shortDateFormat,
      isMiddleNameEnabled: generalSettings?.MIDDLENAME,
      isSuffixEnabled: generalSettings?.SUFFIX,
    }),
    addressSection: mapAddressData(
      cardDetails.profile.addresses,
      addressTypes,
      {
        hideDistrict: !generalSettings?.DISTRICT,
        showOnlyPrimary: true,
      }
    ),
    contactSection: mapContactsData(
      {
        phones: cardDetails.profile.phones,
        mobiles: cardDetails.profile.mobiles,
        emails: cardDetails.profile.emails,
      },
      {
        modeOrder: communicationModeOrder,
        isEmailEnabled: generalSettings?.DISPLAY_EMAIL,
        isMobileEnabled: generalSettings?.DISPLAY_MOBILE,
        isPhoneEnabled: generalSettings?.DISPLAY_PHONE,
        communicationTypes,
        showOnlyPrimary: true,
      }
    ),
    documentSubsenctions: mapDocumentsData(
      cardDetails.profile.identityDocuments,
      {
        shortDateFormat: dateTimeFormats?.shortDateFormat,
        businessDate: propertyConfiguration?.businessDate,
      }
    ),
    complementaryDetails: mapComplementaryData(
      cardDetails.reservation.reservationHeader,
      reservationHeaderDefinition,
      {
        shortDateFormat: dateTimeFormats?.shortDateFormat,
        timeFormat: dateTimeFormats?.timeFormat,
        enabledCustomFields: generalSettings?.RESERVATION_HEADER_CUSTOM_FIELDS,
      }
    ),
    consentsSection: mapConsentsData(cardDetails.profile.consents, consents),
    purposeOfStaySection: mapPurposeOfStay(
      cardDetails.reservation.purposeOfStay
    ),
    disclaimer: getSafeExternalContent(customMessages?.DISCLAIMER),
    registrationCardNumber: cardDetails.confirmationNumber,
    creationDateTime: `${date} ${time}`,
    signature: signatureBase64,
    logo: logoBase64,
  };

  return pdfPresentationData;
}

function* getPDFConfig(): SagasGenerator<RegistrationCardPdfConfig> {
  const generalSettings: GeneralSettingsStorage | undefined = yield select(
    getGeneralSettings
  );

  const pdfConfig: RegistrationCardPdfConfig = {
    isAddressEnabled: generalSettings?.DISPLAY_ADDRESS,
    isContactEnabled:
      generalSettings?.DISPLAY_EMAIL ||
      generalSettings?.DISPLAY_MOBILE ||
      generalSettings?.DISPLAY_PHONE,
    isPurposeOfStayEnabled: generalSettings?.DISPLAY_PURPOSE_OF_STAY,
    isComplementaryDetailsAvailable: Boolean(
      generalSettings?.RESERVATION_HEADER_CUSTOM_FIELDS?.length
    ),
  };

  return pdfConfig;
}

export function* createPDF(signature: Blob): SagasGenerator<Blob> {
  const pdfPresentationData: RegistrationCardPdfData = yield call(
    getPDFPresentationData,
    signature
  );
  const pdfConfig: RegistrationCardPdfConfig = yield call(getPDFConfig);
  const pdfContent: string = createRegistrationCardPdfContent(
    pdfPresentationData,
    pdfConfig
  );

  const pdfBlob: Blob = yield new PDFCreator().createPDF(pdfContent);

  return pdfBlob;
}

function* uploadSignedCard(action: Action<Blob>): SagasGenerator {
  try {
    const sessionId: string = yield select(getElectronicRegistrationProcessId);
    const pdf: Blob = yield call(createPDF, action.payload);

    const payload = new FormData();
    payload.append('file', pdf, 'regCard.pdf');

    yield ElectronicRegistrationProcessApi.uploadSignedCard({
      data: payload,
      customConfig: {
        headers: {
          [KIOSK_SESSION_ID_HEADER]: sessionId,
        },
      },
    });

    yield put(actions.completeRegistrationCard());
    yield put(actions.uploadSignedCard.success());
  } catch (e) {
    yield put(actions.uploadSignedCard.failure(e));
  }
}

function* clearElectronicRegistrationProcess(): SagasGenerator {
  const isSameDeviceScenario: boolean = yield select(getIsSameDeviceScenario);
  const generalSettings: GeneralSettingsStorage = yield select(
    getGeneralSettings
  );
  const isRegistrationCardCancelled: boolean = yield select(
    getRegistrationCardCancelled
  );
  const isRegistrationCardCompleted: boolean = yield select(
    getRegistrationCardCompleted
  );
  const isSameDeviceEnd =
    isSameDeviceScenario &&
    (isRegistrationCardCancelled || isRegistrationCardCompleted);

  if (!isSameDeviceEnd) {
    const defaultLanguage = generalSettings?.LANGUAGE_SETTINGS?.languageCode.toLowerCase();

    if (defaultLanguage && i18next.language !== defaultLanguage) {
      yield put(changeAppLanguage.trigger(defaultLanguage));
    }

    yield put(actions.clearElectronicRegistrationProcess());
  }
}

function* handleAppLanguageChange(): SagasGenerator {
  const sessionId: string | undefined = yield select(
    getElectronicRegistrationProcessId
  );

  if (!sessionId) return;
  yield put(actions.fetchRegistrationCardDetails.trigger(sessionId));
}

export function* electronicRegistrationProcessSagas(): SagasGenerator {
  yield takeLatest(
    actions.startElectronicRegistrationProcess.trigger,
    startElectronicRegistrationProcess
  );
  yield takeLatest(
    actions.cancelElectronicRegistrationProcess.trigger,
    cancelElectronicRegistrationProcess
  );
  yield takeLatest(
    actions.cancelElectronicRegistrationProcess.success,
    clearElectronicRegistrationProcess
  );
  yield takeLatest(
    actions.fetchRegistrationCardDetails.trigger,
    fetchRegistrationCardDetails
  );
  yield takeLatest(
    actions.updateConsentsAndPurposeOfStay.trigger,
    updateConsentsAndPurposeOfStay
  );
  yield takeLatest(actions.uploadSignedCard.trigger, uploadSignedCard);
  yield takeLatest(changeAppLanguage.success, handleAppLanguageChange);
}
